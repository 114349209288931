import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Header from "./Header";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { fetchUsers, fetchRoles, setMatchedUser } from "../store/actions/users";
import { fetchEditedFarms } from "../store/actions/farms";
import UserRoleSelector from "./admin/UserRoleSelector";
import EditFarmsRequests from "./admin/EditFarmsRequests";
import EditedFarms from "./admin/EditedFarms";

const AdminForm = () => {
  const [search, setSearch] = useState("");
  const [match, setMatch] = useState({});
  const [userRole, setUserRole] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const currentUser = useSelector((state) => state.currentUser);
  const users = useSelector((state) => state.users);
  const editedFarms = useSelector((state) => state.editedFarms);
  const userRoles = useSelector((state) => state.userRoles);
  const matchedUser = useSelector((state) => state.matchedUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (matchedUser) {
      setMatch(matchedUser);
      setSearch(matchedUser.email);
      setUserRole(matchedUser.role);
    }
  }, [matchedUser]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      await dispatch(fetchEditedFarms());
      await dispatch(fetchUsers());
      await dispatch(fetchRoles());
      setIsFetching(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event, value) => {
    setSearch(value);
    setMatch({});
    if (!value || value === "") return;

    setSearch(value);

    const matches = users.filter((d) => d.email === value);
    if (!matches.length) return;
    setUserRole(matches[0].role);
    setMatch(matches[0]);
    dispatch(setMatchedUser(matches[0]));
  };

  return (
    <div id="container">
      {currentUser.user.role === "admin" ? (
        <>
          <Header text="Admin Settings" />
          <Grid container direction="column" spacing={2}>
            {!!users.length > 0 && (
              <Grid item>
                <Autocomplete
                  id="search"
                  options={users.map((d) => d.email)}
                  freeSolo
                  value={search}
                  onChange={handleSearch}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Users" />
                  )}
                  fullWidth
                />
              </Grid>
            )}
            {!!Object.keys(match).length > 0 && (
              <>
                <Grid item sx={{ marginTop: 3 }}>
                  <UserRoleSelector
                    role={userRole}
                    user={match}
                    roles={userRoles}
                  />
                </Grid>
                <Grid item sx={{ marginTop: 10 }}>
                  <EditFarmsRequests
                    header={`${match.email} Access Requests`}
                    user={match}
                  />
                </Grid>
              </>
            )}
            {!isFetching &&
              (
                ( Object.keys(editedFarms).length > 0 && Object.keys(match).length === 0 ) 
                || users.filter((user) => user.status === "pending").length
              ) && (
                <Grid item sx={{ mt: 6 }}>
                  <Typography align="center" variant="h5">
                    All Requests
                  </Typography>
                  <EditedFarms
                    editedFarms={editedFarms}
                    pendingUsers={
                      users.filter((user) => user.status === "pending") || []
                    }
                    user={false}
                  />
                </Grid>
              )}
          </Grid>
        </>
      ) : (
        <Typography variant="h5" align="center">
          Please log in with admin credentials to access this page
        </Typography>
      )}
    </div>
  );
};

export default AdminForm;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { submitFeedback, resetFeedback } from '../store/actions/feedback';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const Feedback = () => {
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFeedbackHidden, setIsFeedbackHidden] = useState(false);
    const [feedbackError, setFeedbackError] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const feedbackData = useSelector((state) => state.feedback);
    const useStyles = makeStyles((theme) => ({
        helperTextStyles: {
          '& .MuiFormHelperText-root': {
            margin: '.5rem 0 0',
            lineHeight: 1.2
          },
        },
    }));
    const classes = useStyles();
    const handleSubmitFeedback = useCallback( event => {
        event.preventDefault()
        setIsLoading(true)
        let payload = {
            feedback,
            page: location.pathname,
            email: currentUser?.user?.email
        }
        // if (currentUser) {
        //     payload.email = ;
        // }
        dispatch(submitFeedback(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedback, currentUser, dispatch, location, submitFeedback]);

    const handleResetFeedback = useCallback( event => {
        event.preventDefault()
        setFeedback('')
        dispatch(resetFeedback());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, resetFeedback]);

    const handleToggleFeedback = () => {
        setIsFeedbackHidden(!isFeedbackHidden)
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
            setFeedbackError(feedbackData.error ? 'There was an error submitting your feedback. Please try again!' : false) 
        }, 1000);
        
    }, [feedbackData]);

    useEffect(() => {
        setFeedbackError(false) 
    }, [feedback]);

    return (
        <div>
            {
                isFeedbackHidden 
                    ? (
                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            onClick={handleToggleFeedback}
                            sx={{ position: "fixed", right: '1rem', bottom: '1rem', zIndex: 999 }}
                            color="primary"
                            >
                            Provide feedback
                        </Button>
                    )
                    : (
                        <Box
                            component="section" 
                            className="feedback"
                            sx={{
                                position: 'fixed',
                                width: 300,
                                right: '1rem',
                                bottom: '1rem',
                                padding: '.5rem 1rem 1.5rem',
                                borderRadius: '0.5rem',
                                backgroundColor: 'rgba(255,255,255,.9)',
                                zIndex: 999,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            }}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                spacing={2}>
                                <Button variant="text" onClick={handleToggleFeedback}>Hide</Button>
                            </Stack>
                            {
                                (feedbackData.feedback === null || feedbackError || isLoading)
                                ? (
                                    <Box
                                        component="form"
                                        sx={{
                                            
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        onSubmit={handleSubmitFeedback}
                                        >
                                        <Stack spacing={2}>
                                            <TextField
                                                className={classes.helperTextStyles}
                                                id="outlined-multiline-flexible"
                                                error={feedbackError}
                                                label="Provide feedback for this page"
                                                multiline
                                                value={feedback}
                                                onChange={ e => setFeedback(e.target.value) }
                                                maxRows={6}
                                                rows={3}
                                                width="100%"
                                                helperText={feedbackError || 'Your feedback will help us to improve the app!'}
                                                sx={{ backgroundColor: 'white' }}
                                            />
                                            <Button
                                                size="small"
                                                type="submit"
                                                disabled={isLoading || feedback === ''}
                                                variant="contained"
                                                sx={{ position: "relative", marginRight: ".5rem" }}
                                                color="primary"
                                                >
                                                { isLoading ? 'Submitting feedback' : 'Submit feedback' }
                                            </Button>
                                        </Stack>
                                    </Box>
                                )
                                : (
                                    <Box
                                        className="reset-feedback"
                                        component="form"
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                        noValidate
                                        autoComplete="off"
                                        onSubmit={handleResetFeedback}
                                    >
                                        <Typography variant="subtitle1" gutterBottom>
                                            Thanks for the feedback!
                                        </Typography>
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            sx={{ position: "relative", marginRight: ".5rem" }}
                                            color="primary"
                                            >
                                            Submit additional feedback
                                        </Button>
                                    </Box>
                                )
                            }
                            
                        </Box>
                    )
            }
        </div>
    )
}

export default Feedback
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { userRoles } from '../data';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { updateUser } from '../../store/actions/users';

const UserRoleSelector = ({ role, user, roles }) => {
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState('');
    const [status, setStatus] = useState(false);
    const handleSubmit = () => {
        const payload = {
            _id: user._id,
            email: user.email,
            role: selectedRole,
            originalRole: user.role,
            status: status,
            originalStatus: user.status
        };
        dispatch(updateUser(payload));
    };

    useEffect(() => {
        setSelectedRole(role);
    }, [role]);

    useEffect(() => {
        setStatus(user.status === 'active');
    }, [user])

    return (
        <>
            <Grid container alignItems={'center'}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel id="user-role-label">User Role</InputLabel>
                            <Select
                                labelId="user-role-label"
                                id="demo-simple-select"
                                label="User Role"
                                value={selectedRole}
                                onChange={(e) => {setSelectedRole(e.target.value)}}
                            >
                                { roles.map((it, index) => {
                                    return (
                                        <MenuItem value={it.value} key={index}> { it.label } </MenuItem>
                                    )
                                }) }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={status} onChange={(e) => { setStatus(e.target.checked); }} />}
                            label="Approve User"
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Apply
                    </Button>
                </Stack>
            </Grid>
        </>
    )
}

export default UserRoleSelector;
import { SET_EDITED_FARMS } from '../actionTypes';
import { FARM_PROPS } from '../../components/data';

const editedFarms = (state = {}, action) => {
  switch (action.type) {
    case SET_EDITED_FARMS:
      let { data } = action;
      return data;
    default:
      return state;
  }
};

export default editedFarms;

import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const RoleSelector = ({role, setter, roles}) => {
  roles = roles.filter( userRole => userRole.value !== 'admin' )
  return (
    <div>
        <FormControl sx={{ marginBottom: 3 }}>
            <FormLabel>I am registering as a(n)</FormLabel>
            <RadioGroup
                row
                value={role}
                onChange={(e) => setter(e.target.value)}
            >
                {roles.map(({ value, label }) => (
                    <FormControlLabel
                        key={value}
                        value={value}
                        label={label}
                        control={<Radio/>}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    </div>
  );
};

export default RoleSelector;

import { SET_REQUEST_FARM, REMOVE_REQUEST_FARM } from '../actionTypes';
const DEFAULT_STATE = {
    farms: localStorage.requestFarms ? JSON.parse(localStorage.requestFarms) : []
};
  
const requestFarms = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case SET_REQUEST_FARM:
        return {
            farms: action.farms
        }
      default:
        if (localStorage.requestFarms) {
          let farms = JSON.parse(localStorage.requestFarms);
          return {
            farms: farms
          }
        }
        return state;
    }
  };
  
  export default requestFarms;
  
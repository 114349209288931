import Header from './Header';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { submitRequestFarms, removeRequestFarm, clearRequestFarms } from '../store/actions/auth';
import _ from 'lodash';

const AccessFarmRequestForm = ({ heading }) => {
    const requestFarms = useSelector((state) => state.requestFarms);
    const currentUser = useSelector((state) => state.currentUser);
    const history = useHistory();
    const [registered, setRegistered] = useState(false)
    const dispatch = useDispatch();
    
    useEffect(() => {
        if ( !currentUser.user.id || currentUser.user.status !== 'pending') {
            history.push("/signup");
        }
    }, [currentUser]);

    useEffect(() => {
        if (registered) {
            history.push('/completed-register');
        }
    }, [registered]);

    const handleEditFarm = id => {
        history.push(`/farm/request/${id}`)
    }

    const handleSubmit = () => {
        const payload = {
            user_id: currentUser.user.id,
            user_email: currentUser.user.email,
            farms: requestFarms.farms
        };
        dispatch(submitRequestFarms(payload)).then(() => {
            setRegistered(true)
            dispatch(clearRequestFarms());
        });
        
    }

    const removeFarm = (farmId) => {
        dispatch(removeRequestFarm(farmId));
    }

    return (
        <div>
            <Grid container spacing={3} sx={{ marginTop: 5 }}>
                <Grid item xs />
                <Grid item xs={12} md={8}>
                    <Header text={heading} align="center" />
                    { requestFarms.farms && requestFarms.farms.map((d, index) => {
                        return (
                            <div key={index}>
                                <Box sx={{ display: 'flex', flexDirection: {sm: 'row', xs: 'column'}, flexWrap: {sm: 'wrap', xs: 'nowrap'}, padding: 3, border: 1, borderRadius: '5px', borderColor: 'grey.500', marginBottom: 2, alignItems: 'center' }}>
                                    <Box component="span" sx={{ flex: 1, textAlign: {sm: 'left', xs: 'center'} }}>
                                        { d.label }
                                    </Box>
                                    <Grid item sx={{marginLeft: {sm: 1, xs: 0}, marginTop: {sm: 0, xs: 2}}}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => handleEditFarm(d.properties.id)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            sx={{marginLeft: 1}}
                                            onClick={() => {removeFarm(d.properties.id)}}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                </Box>
                            </div>
                        )
                    }) }
                    <Stack spacing={2}  justifyContent="start" alignItems="center"  sx={{ marginTop: 5 }}>
                        <Button
                            variant="contained"
                            onClick={() => history.push('/farm/request')}
                        >
                            <AddCircleOutlineRoundedIcon sx={{ marginRight: 2 }}/>
                            Add Farm/Garden
                        </Button>

                    </Stack>
                    <Stack spacing={2}  justifyContent="start" alignItems="center"  sx={{ marginTop: 5, marginBottom: 10 }}>
                        <Button
                            variant="contained"
                            onClick={() => { handleSubmit() }}
                        >
                            Submit
                        </Button>

                    </Stack>
                </Grid>
                <Grid item xs />
            </Grid>
        </div>
    )
};

export default AccessFarmRequestForm;
import { SET_ACCESS_FARMS } from '../actionTypes';
const DEFAULT_STATE = {
    farms: []
};
  
const requestFarmsAdmin = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case SET_ACCESS_FARMS:
        return {
            farms: action.farms
        }
      default:
        return state;
    }
  };
  
  export default requestFarmsAdmin;
  
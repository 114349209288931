import { apiCall } from '../../services/api';
import { SET_USERS, SET_USER_ROLES, SET_MATCHED_USER } from '../actionTypes';
import { setAlert } from './alert';

export function setUsers(users) {
  return {
    type: SET_USERS,
    users,
  };
}

export function fetchUsers() {
  return async (dispatch) => {
    try {
      const users = await apiCall('get', '/api/user/fetch');
      dispatch(setUsers(users));
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function editUser(user) {
  return async (dispatch) => {
    try {
      await apiCall('post', '/api/user/edit', user);
      dispatch(fetchUsers());
      dispatch(
        setAlert({
          severity: 'success',
          message: `Successfully updated ${user.email}!`,
        })
      );
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function updateUser(payload) {
  return async (dispatch) => {
    try {
      await apiCall('post', '/api/user/update', payload);
      dispatch(fetchUsers());
      dispatch(
        setAlert({
          severity: 'success',
          message: `Successfully updated ${payload.email}!`,
        })
      );
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  }
}

export function setUserRoles(roles) {
  return {
    type: SET_USER_ROLES,
    roles,
  };
}


export function fetchRoles() {
  return async (dispatch) => {
    try {
      const roles = await apiCall('get', '/api/roles');
      dispatch(setUserRoles(roles));
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  }
}

export function setMatchedUser(user) {
  return {
    type: SET_MATCHED_USER,
    user: user
  }
}
import React, { useEffect, useState, useCallback } from 'react';
import { apiCall } from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';

import {
  setEdit,
  deleteOne,
  setSelected,
  handleChangeFarm,
  handleAccessFarm,
  setFarmEdits,
  submitFarm
} from '../../store/actions/farms';

import { submitRequestFarms, addRequestFarm } from '../../store/actions/auth'

import { FARM_PROPS, FARM_DEFAULT, ENVIRONMENTS } from '../data';
import Header from '../Header';
import SectionHeader from './SectionHeader';
import { TextForm, SelectForm, DateForm, NumericGridForm } from './Inputs';
import { VerifyDialog, DeleteDialog } from './Dialogs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import FarmEdits from './FarmEdits'
import { ArrowBackIosNew, AddCircle } from '@mui/icons-material';

const FarmForm = () => {
  
  const [authUsers, setAuthUsers] = useState([]); // verified address from dialog selection
  const [geocodes, setGeocodes] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const currentUser = useSelector( state => state.currentUser );

  // TESTING ONLY
  // currentUser = {
  //   isAdmin: false,
  //   user: {
  //     email: 'email',
  //     username: 'hi',
  //     status: 'pending',
  //     _id: '6287c1cc7d70ebe8f8a3f8ff'
  //   }
  // }

  const { farmId, formType } = useParams();

  const farm = useSelector( state => state.farm);
  const edit = useSelector( state => state.edit);
  // const farmEdits = useSelector( state => state.farmEdits);
  const requestFarms = useSelector( state => state.requestFarms);
  const [restricted, setRestricted] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [shouldRequestAccess, setShouldRequestAccess] = useState(false);
  const [accessRequested, setAccessRequested] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(formType !== 'request');
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const methods = useForm({ defaultValues: FARM_DEFAULT });
  const { getValues, setValue, watch, reset, control, handleSubmit } = methods;

  const watchEnviro = watch('environments', []);
  const watchEnviroDetails = watch('enviroDetails', []);
  const watchGrowMethods = watch('growMethods', []);
  const watchCompost = watch('compost', '');
  const watchAll = watch()

  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    if ( farmId === undefined ) {
      setShowForm(formType === 'create')
      dispatch(setEdit(formType, null))
    } else {
      if ( edit ) {
        if ( edit.properties.id !== farmId ) {
          dispatch(setEdit(formType, farmId))
        } else {
          if ( edit.shouldShowForm ) {
            setShowForm(true)
          }
          if ( edit.shouldRequestAccess ) {
            setShouldRequestAccess(true)
          }
          if (edit.properties) {
            Object.entries(edit.properties).forEach(([k, v]) => {if (v) { setValue(k, v) }});
            setOriginalData(getValues())
          }
        }
      } else {
        if ( farm.features ) {
          dispatch(setEdit(formType, farmId))
        }
      }
    }
     
  }, [farmId, dispatch, edit, farm, formType, setValue, getValues])

  useEffect(() => {
    if ( !currentUser.user.id ) {
      history.push('/signin');
    }
    if ( Object.keys(originalData).length && currentUser.isAdmin ) {
      dispatch(setFarmEdits(originalData))
    }
  }, [dispatch, originalData, currentUser]);

  useEffect( () => {
    if ( farm.features ) {
      if ( !searchOptions.length ) {
        setSearchOptions(farm.features)
      }
    }
  }, [farm, searchOptions])
  
  const handleClear = useCallback(() => {
    setRestricted(false)
    setShowForm(false)
    setShouldRequestAccess(false)
    setAccessRequested(false)
    setGeocodes([])
    dispatch(setEdit(null, null));
    reset();
    if ( currentUser.user.status === 'pending' ) {
      history.push('/request-access')
    }
  }, [dispatch, reset]);

  const handleDelete = () => {
    const data = getValues();
    dispatch(deleteOne({ data })).then(() => dispatch(setSelected(null)));
    handleClear();
    setOpenDelete(false);
  };

  const onSubmit = (data, geoData = null) => {
    // Check for geocode before submitting
    const { address } = data;
    if ( address !== originalData.address && !geoData ) {
      apiCall('post', '/api/maps/geocode', { address })
      .then( geoData => {
          if ( geoData.length && geoData.latitude !== null ) {
            setGeocodes(geoData)
          }
      })
    } else {
      updateFarmData(data, null)
    }
  };

  const updateFarmData = (data, geoData) => {
    data.address = geoData ? geoData.formattedAddress : originalData.address
    data.lat = geoData ? geoData.latitude : originalData.lat
    data.lon = geoData ? geoData.longitude : originalData.lon

    let area = 0;
    area += data.areaOther ? parseInt(data.areaOther) : 0;
    area += data.areaFloor2 ? parseInt(data.areaFloor2) : 0;

    if ( data.environments.includes('Indoor') ) {
      area += data.areaCellar ? parseInt(data.areaCellar) : 0;
      area += data.areaFloor1 ? parseInt(data.areaFloor1) : 0;
    }

    if ( data.enviroDetails.includes('Farm- or garden-specific structure') ) {
      area +=data.areaGround ?  parseInt(data.areaGround) : 0;
    }

    if ( data.enviroDetails.includes('Roof') ) {
      area += data.areaRoof ? parseInt(data.areaRoof) : 0;
    }

    if (area > 0) {
      data.area = area; // filtering fields with 0 length below
    }

    const { socials } = data;

    if (socials && !Array.isArray(socials)) {
      data.socials = socials.split(',');
    }

    data.modifiedBy = currentUser.user.email

    const dataEdits = data.edits
    // only update fields with data
    data = Object.keys(data)
      .filter((k) => typeof data[k] === 'number' || (data[k] && data[k].length))
      .reduce((a, k) => Object.assign(a, { [k]: data[k] }), {});
    
    data.edits = dataEdits
    if ( 
      (currentUser.user.status === 'pending' || shouldRequestAccess) ||
      ( formType === 'create' && !currentUser.user.permissions.add_farm ) )  {
      const requestId = data.id || tempFarmId()
      data = {
        geometry: {
          coordinates: [ data.lon, data.lat ],
          type: 'Point'
        },
        modifiedBy: currentUser.user.email,
        label: `${data.name} (${data.address})`,
        properties: {
          ...data,
          id: data.id || requestId,
        }, 
        type: 'Feature'
      }
      data.user_id = currentUser.user.id
      
      if ( formType === 'create' ) {
        data.newRequest = true
      }

      if ( formType === 'request' || formType === 'edit' ) {
        data.editRequest = true
        data.shouldShowForm = true
        data.requestAccess = true
      }
      if ( currentUser.user.status !== 'pending' ) {
        dispatch(submitRequestFarms(
          {
            user_id: currentUser.user.id,
            user_email: currentUser.user.email,
            farms: [data]
          }
        ))
      } else {
        dispatch(addRequestFarm(data));
        history.push('/request-access');
      }

      handleClear()
    }
    // if ( ( formType === 'edit' && (!currentUser.isAdmin && currentUser.user.status !== 'pending' && editFarm.properties.authUsers && editFarm.properties.authUsers.includes(currentUser.user.id)) || ( !currentUser.isAdmin && !editFarm.properties.authUsers) ) || formType === 'request' ) {
    
    if ( 
      ( formType === 'create' && currentUser.user.permissions.add_farm ) || 
      ( formType === 'edit' && !shouldRequestAccess )
      ) {
      if ( formType === 'create' ) {
        data.newRequest = true
      }

      if ( formType === 'edit' ) {
        data.editRequest = true
      }
      dispatch(submitFarm({ farm: data, original: edit })).then( result => {
        if ( formType === 'create' ) {
          handleClear()
        } else {
          setOriginalData({ ...originalData, properties: data })
        }
      })
    }
  }

  const tempFarmId = () => {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }

  const createNewFarm = () => {
    history.push('/farm/create')
  }

  const addFarmForRequest = () => {
    if (edit) {
      if ( currentUser.user.status === 'pending' ) {
        edit.requestAccess = true
        edit.shouldRequestAccess = false
        edit.shouldShowForm = true
        
        dispatch(addRequestFarm(edit));
        history.push('/request-access');
      } else {
        dispatch(submitRequestFarms(
          {
            user_id: currentUser.user.id,
            user_email: currentUser.user.email,
            farms: [edit]
          }
        )).then(() => {
          setAccessRequested(true)
        });
      }
      handleClear();
    }
  }

  const rejectChanges = () => {
    let payload = {
      farm_id: edit.properties.origin_farm_id,
      user_id: edit.properties.user_id,
      status: 'reject',
      data: {}
    };
    dispatch(handleChangeFarm(payload)).then((res)=> {
      history.push('/admin');
    });
  }

  const handleSelectFarm = selectedFarm => {
    if ( !selectedFarm ) {
      history.push(`/farm/${formType}`)
      return
    }
    let formPrefix = 'edit'
    if ( ( formType === 'edit' && (!currentUser.isAdmin && currentUser.user.status !== 'pending' && selectedFarm.properties.authUsers && selectedFarm.properties.authUsers.includes(currentUser.user.id)) || ( !currentUser.isAdmin && !selectedFarm.properties.authUsers) ) || formType === 'request' ) {
      formPrefix = 'request'
    }

    history.push(`/farm/${formPrefix}/${selectedFarm.properties.id}`)
  }

  const handleVerify = geoData => {
    const data = getValues()
    updateFarmData( data, geoData )
  };

  const handleSearchAgain = () => { 
    setOriginalData({})
    handleClear()
    history.push(`/farm${formType === 'request' ? '/request' : ''}`)
  }

  const handleAccess = (user, status) => {
    let users = getValues('authUsers') || []
    setAuthUsers(users)
    if ( status === 'approve' ) {
      users.push(user.id)
    }
    if ( status === 'reject' ) {
      setRejectedUsers([...rejectedUsers, user.id])
    }
    setValue('authUsers', users)
    dispatch(handleAccessFarm(user, edit.properties, status))
  }

  const handleSetFieldEdit = edit => {
    setValue(edit.key, edit.value)
    const editsObj = getValues('edits')
    editsObj[edit.key] = {
      modifiedBy: edit.modifiedBy,
      edit: edit.value
    }
    setValue('edits', editsObj)
  }

  useEffect(() => {
    const shouldAllowSubmit = JSON.stringify(watchAll) !== JSON.stringify(originalData)
    setAllowSubmit(shouldAllowSubmit)
    // Remove edits from edits field if the field is changed
    let editsObj, newEditsObj
    editsObj = newEditsObj = getValues('edits')
    Object.keys(editsObj).forEach( field => {
      const fieldVal = getValues(field)
      if ( fieldVal !== editsObj[field].edit ) {
        delete newEditsObj[field];
      }
    })
    if ( JSON.stringify(editsObj) !== JSON.stringify(newEditsObj) ) {
      setValue('edits', newEditsObj)
    }
  }, [watchAll, originalData]);

  const floors = FARM_PROPS.floors.fields.filter((d) => {
    let re = new RegExp(d.pattern, 'i');
    return (
      watchEnviro.some((x) => re.test(x)) ||
      watchEnviroDetails.some((x) => re.test(x))
    );
  });

  const marks = Array(5)
    .fill()
    .map((d, i) => i * 20 + 20)
    .map((d) => ({ value: d, label: `${d}%` }));
  
  const omittedFarms = requestFarms.farms ? requestFarms.farms.map( requestFarm => requestFarm.properties.id ) : []
  const formTypes = ['create', 'request', 'edit']
  if (formType !== undefined && !formTypes.includes(formType)) return <Grid sx={{ marginTop: 5 }} item align="center"><h3>PAGE NOT FOUND</h3></Grid>
  return (
    <div id="container">
      <FormProvider {...methods}>
        {
          formType === 'view' &&
          <Grid item>
            <Button variant="text" onClick={() => {history.push('/admin');}}>
              <ArrowBackIosNew fontSize={'8px'} sx={{ marginRight: 1 }} />
              Cancel
            </Button>
          </Grid>
        }
        <Header
          text={ 
            formType !== 'view' 
            ? formType === 'create'
              ? "Create new farm/garden" 
              : edit === null
                ? "Add New or Edit Existing Farm/Garden Location" 
                : restricted 
                  ? 'You do not have access to edit this location'
                  : `Editing ${edit.properties.name}`
            : 'Review Grower Edits'
          }
          align={'center'}
        />
        { 
          ((farmId !== undefined || formType === 'create') && showForm ) &&
            <Grid sx={{ marginBottom: 5 }} item align="center">
              <Button align={'center'} variant="contained" color="primary" onClick={handleSearchAgain}>Search Again</Button>
              {
                (formType === 'request' && currentUser.user.status === 'pending') && <Button sx={{ marginLeft: '1rem' }} align={'center'} variant="contained" color="primary" onClick={() => handleClear()}>Back</Button>
              }
            </Grid>
        }
        { 
          (( !showForm ) || ( formType === undefined && farmId === undefined )) && (
            <FormControl fullWidth>
              <Autocomplete
                options={searchOptions}
                freeSolo={false}
                value={edit}
                // onInputChange={(event: object, value: string, reason: string) => {
                //   if (reason === 'input') {
                //     changeOptionBaseOnValue(value);
                //   }
                // }}
                disabled={!searchOptions.length}
                noOptionsText={
                  <div>
                    No locations found
                    <Button style={{ marginLeft: '1rem' }} variant="contained" color="primary" onMouseDown={createNewFarm} endIcon={<AddCircle />}>
                      Add farm/garden
                    </Button>
                  </div>
                }
                getOptionDisabled={(option) => {
                  return omittedFarms.includes(option.properties.id)
                }}
                onChange={(e, v) => handleSelectFarm(v ? v : null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Existing Locations to Edit"
                  />
                )}
              />
              <FormHelperText>
                { formType === 'request' ? 'If no farm or garden is found, you can create a new one.' : 'Leave blank to create a new farm/garden location'}
              </FormHelperText>
              {
                (edit !== null && !showForm) && 
                <Grid item align={'right'}>
                  {
                    formType === 'request' && 
                    <Button
                      variant="contained"
                      sx={{ marginLeft: 'auto' }}
                      onClick={ () => setShowForm(true) }
                    >
                      Edit
                    </Button>
                  }
                  {
                    shouldRequestAccess &&
                    <Button
                      variant="contained"
                      sx={{ marginLeft: '1rem' }}
                      onClick={()=>addFarmForRequest()}
                    >
                      Request Access
                    </Button>
                  }
                </Grid>
              }
              {
                accessRequested && <Grid item align={'center'}><p>Access Requested!</p></Grid>
              }
            </FormControl>
          )
        }
        {
          ( !showForm && currentUser.isAdmin ) && 
          <Box textAlign="right">
            <Button
              variant="contained"
              sx={{ marginLeft: 'auto' }}
              onClick={ () => history.push('/farm/create') }
            >
              Create New Farm/Garden
            </Button>
          </Box>
        }
        {
          ( currentUser.isAdmin && 
            edit && 
            edit.accessRequests &&
            Object.keys(edit.accessRequests).length > 0 && 
            Object.keys(edit.accessRequests).filter( access => authUsers.includes(access) || rejectedUsers.includes(access)).length !== Object.keys(edit.accessRequests).length 
          ) &&
          <FormControl fullWidth>
            <Stack spacing={2}>
              <SectionHeader text="Users Requesting Access" />
              <Stack>
                {
                  Object.keys(edit.accessRequests).map( accessRequest => {
                    return (
                      <div key={`access-request-${accessRequest}`}>
                        {
                          ( authUsers.includes(accessRequest) || rejectedUsers.includes(accessRequest) )
                          ? <></>
                          : <Grid item xs={12} sx={{ mb: 2 }}>
                              <Box key={`user-${accessRequest}`} sx={{ display: 'flex', flexDirection: {md: 'row', xs: 'column'}, flexWrap: {sm: 'wrap', xs: 'nowrap'}, padding: 2, border: 1, borderRadius: '5px', borderColor: 'grey.500', marginBottom: 2, alignItems: 'center' }}>
                                <Box component="span" sx={{ flex: 1, textAlign: {md: 'left', xs: 'center'} }}>{ edit.accessRequests[accessRequest].email }</Box>
                                <Grid item sx={{marginLeft: {md: 1, xs: 0}, marginTop: {md: 0, xs: 2}}}>
                                  <Button variant="contained" color="primary" onClick={ () => handleAccess(edit.accessRequests[accessRequest], 'approve') }>
                                    Approve
                                  </Button>
                                </Grid>
                                <Grid item sx={{marginLeft: {md: 1, xs: 0}, marginTop: {md: 0, xs: 2}}}>
                                  <Button variant="contained" color="error" onClick={ () => handleAccess(edit.accessRequests[accessRequest], 'reject') }>
                                    Reject
                                  </Button>
                                </Grid>
                              </Box>
                            </Grid>
                        }
                      </div>
                    )
                  })
                }
              </Stack>
            </Stack>
          </FormControl>
        }
        { ( (edit !== null || formType === 'create') && !restricted && showForm ) && 
          <Grid container>
            {
              formType !== 'view' && 
                <Grid container justifyContent="flex-end"  sx={{ marginTop: 3 }}>
                  <Grid item>
                    <Button variant="outlined" color="secondary" onClick={handleClear}>
                      Clear Form
                    </Button>
                  </Grid>
                </Grid>
            }
            <Grid item md={12}>
              <Stack spacing={2}>
                <SectionHeader text="Farm/Garden Characteristics" />
                <TextForm setValue={handleSetFieldEdit} name="address" />
                <TextForm setValue={handleSetFieldEdit} name="name" />
                {
                  formType === 'request' &&
                  <TextForm setValue={handleSetFieldEdit} name="relationship" helperText="Provide a brief description of your relationship with this farm/garden"/>
                }
                <TextForm setValue={handleSetFieldEdit} name="orgName" />
                <TextForm setValue={handleSetFieldEdit} name="headquarters" />
                <TextForm setValue={handleSetFieldEdit} name="website" />
                <TextForm setValue={handleSetFieldEdit} name="socials" />
                <SelectForm setValue={handleSetFieldEdit} name="type" />
                <SelectForm setValue={handleSetFieldEdit} name="environments" />
                {!!watchEnviro.length && (
                  <SelectForm
                    name="enviroDetails"
                    label="Location detail"
                    options={watchEnviro.map((d) => ENVIRONMENTS[d]).flat()}
                    multiple
                  />
                )}
                {!!floors.length && (
                  <NumericGridForm setValue={handleSetFieldEdit} name="floors" fields={floors} adorn="sqft" />
                )}
                <Controller
                  name="percentFood"
                  control={control}
                  defaultValue={80}
                  render={({ field }) => (
                    <FormControl>
                      <Grid container>
                        <Grid item md={12}>
                          <Grid container>
                            <FormLabel style={{ paddingRight: 12 }}>
                              How much of your space is dedicated to food
                              production?
                            </FormLabel>
                            <FarmEdits name="percentFood" onClick={handleSetFieldEdit} type="string"/>
                          </Grid>
                          <Slider
                            step={1}
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(v) => `${v}%`}
                            marks={marks}
                            {...field}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  )}
                />
                <FormControl>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SelectForm setValue={handleSetFieldEdit} name="orgType" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectForm setValue={handleSetFieldEdit} name="bCorp" />
                    </Grid>
                  </Grid>
                </FormControl>
                <SelectForm setValue={handleSetFieldEdit} name="priorities" />
                <SelectForm setValue={handleSetFieldEdit} name="accessibility" />
                <SelectForm setValue={handleSetFieldEdit} name="publicity" />
                <SectionHeader text="Food Production" />
                <SelectForm setValue={handleSetFieldEdit} name="growMethods" />
                {watchGrowMethods.join(',').includes('Aqua') && (
                  <SelectForm setValue={handleSetFieldEdit} name="aquaculture" />
                )}
                {watchGrowMethods.join(',').includes('ponics') && (
                  <SelectForm setValue={handleSetFieldEdit} name="aquaponics" />
                )}
                <SelectForm setValue={handleSetFieldEdit} name="iot" />
                <SelectForm setValue={handleSetFieldEdit} name="usdaOrganic" />
                <SelectForm setValue={handleSetFieldEdit} name="compost" />
                {watchCompost === 'Off-site' && (
                  <SelectForm setValue={handleSetFieldEdit} name="compostOffsite" />
                )}
                {watchCompost === 'On-site' && (
                  <SelectForm setValue={handleSetFieldEdit} name="compostOnsite" />
                )}
                <SelectForm setValue={handleSetFieldEdit} name="wastewater" />
                <SelectForm setValue={handleSetFieldEdit} name="dischargePermit" />
                <SectionHeader text="Harvest, processing, and distribution" />
                <NumericGridForm setValue={handleSetFieldEdit} name="crops" adorn="lbs" />
                <NumericGridForm setValue={handleSetFieldEdit} name="distros" adorn="lbs" />
                <SelectForm setValue={handleSetFieldEdit} name="distroRegion" />
                <SectionHeader text="Land ownership and use" />
                <Grid container>
                  <Grid item md={6}>
                    <DateForm setValue={handleSetFieldEdit} name="founding" views={['year']} />
                  </Grid>
                </Grid>
                <SelectForm setValue={handleSetFieldEdit} name="ownership" />
                <SelectForm setValue={handleSetFieldEdit} name="zoning" />
                <FormControl>
                  <FormLabel sx={{ marginBottom: 1 }}>
                    What is the start and end date of your current lease
                    agreement?
                  </FormLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DateForm setValue={handleSetFieldEdit} name="leaseStart" />
                    </Grid>
                    <Grid item xs={6}>
                      <DateForm setValue={handleSetFieldEdit} name="leaseEnd" />
                    </Grid>
                  </Grid>
                </FormControl>
                <TextForm setValue={handleSetFieldEdit} name="rent" />
                <TextForm setValue={handleSetFieldEdit} name="landValue" />
                <SectionHeader text="Labor" />
                <SelectForm setValue={handleSetFieldEdit} name="fullTime" />
                <SelectForm setValue={handleSetFieldEdit} name="farmHand" />
                <SelectForm setValue={handleSetFieldEdit} name="farmManager" />
                <SelectForm setValue={handleSetFieldEdit} name="opsManager" />
                <SelectForm setValue={handleSetFieldEdit} name="eventsManager" />
                <SelectForm setValue={handleSetFieldEdit} name="partTime" />
                <SelectForm setValue={handleSetFieldEdit} name="volunteers" />
                <SelectForm setValue={handleSetFieldEdit} name="volunteerHours" />
                <SelectForm setValue={handleSetFieldEdit} name="localWorkers" />
                <SectionHeader text="Enrichment opportunities" />
                <SelectForm setValue={handleSetFieldEdit} name="studentPrograms" />
                <SelectForm setValue={handleSetFieldEdit} name="skillsPrograms" />
                <TextForm setValue={handleSetFieldEdit} name="outreachHours" />
                <SelectForm setValue={handleSetFieldEdit} name="incomeSources" />
                <SelectForm setValue={handleSetFieldEdit} name="capInvestments" />
                <SelectForm setValue={handleSetFieldEdit} name="renewableEnergy" />
                <div style={{ display: 'none' }}>
                  <TextForm setValue={handleSetFieldEdit} name="edits" />
                </div>
                <Stack spacing={4}>
                  {currentUser.isAdmin && (
                    <Stack spacing={2}>
                      <SectionHeader text="Database Admin" />
                      <TextForm setValue={handleSetFieldEdit} name="authUsers" label="Users Authorized to Edit" />
                    </Stack>
                  )}
                  <Stack direction="row" spacing={2}>
                    { (!!edit && formType === 'common') && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDelete(true)}
                      >
                        Delete
                      </Button>
                    )}
                    {
                      ( (edit && !edit.properties.needsApproval) || formType === 'create' ) && <Button
                        variant="contained"
                        onClick={ handleSubmit( data => {
                          onSubmit( data, null )
                        }) }
                        disabled={!allowSubmit}
                      >
                        {
                          formType === 'edit' ? 'Submit Edits' : 'Submit'
                        }
                      </Button>
                    }
                    {
                      ( edit && edit.properties.needsApproval) && 
                      <>
                        <Button
                          variant="contained"
                          onClick={methods.handleSubmit(onSubmit)}
                        >
                          Accept Farm
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {rejectChanges()}}
                        >
                          Reject Farm
                        </Button>
                      </>
                    }
                  </Stack>
                  {
                    !allowSubmit && 
                      <FormHelperText>
                        No changes have been made to this location
                      </FormHelperText>
                  }
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        }
      </FormProvider>
      {
        <>        
          <VerifyDialog
            open={geocodes.length > 0}
            geocodes={geocodes}
            handleClose={() => setGeocodes([])}
            handleVerify={ handleVerify }
          />
          <DeleteDialog
            open={openDelete}
            handleClose={() => setOpenDelete(false)}
            handleDelete={handleDelete}
          />
        </>
      }
    </div>
  );
};

export default FarmForm;

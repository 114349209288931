import { apiCall } from '../../services/api';
import { SET_EDIT, SET_FARMS, SET_FARM_EDITS, UPDATE_FARMS_LIST } from '../actionTypes';

const farms = ({getState, dispatch}) => (next) => (action) => {
    const { farm, requestFarms, currentUser } = getState()
    
    switch(action.type) {
        case SET_EDIT:
            const { formType, farmId } = action
            let editFarm = null
            if ( requestFarms.farms.length ) {
                requestFarms.farms.forEach( requestFarm => {
                    if ( requestFarm.properties.origin_farm_id === farmId || requestFarm.properties.id === farmId ) {
                        requestFarm.shouldRequestAccess = false
                        editFarm = requestFarm
                    }
                })
            }
            if ( editFarm === null && farmId && farm.features ) {
                editFarm = farm.features.find(obj => {
                    return obj.properties.id === farmId
                })
            }
            if ( editFarm ) {
                if ( 
                    ( 
                        formType === 'edit' && 
                        (
                            (
                                !currentUser.isAdmin && 
                                currentUser.user.status !== 'pending' && 
                                editFarm.properties.authUsers && 
                                editFarm.properties.authUsers.includes(currentUser.user.id)
                            ) || 
                            ( 
                                !currentUser.isAdmin && 
                                !editFarm.properties.authUsers
                            ) 
                        )
                    ) 
                    || (formType === 'request') ) {
                    editFarm.shouldRequestAccess = true
                } else {
                    editFarm.shouldShowForm = true
                }
            }

            if ( editFarm && currentUser.isAdmin && !editFarm.accessRequests ) {
                editFarm.accessRequests = {}
                apiCall('get', `/api/farm/admin/fetchFarmAccessRequests/${editFarm.properties.id}`)
                .then( accessRequests => {
                    if ( accessRequests.length ) {
                        accessRequests.forEach( accessRequest => {
                            if ( !editFarm.accessRequests[accessRequest.user_id] ) {
                                editFarm.accessRequests[accessRequest.user_id] = { email: accessRequest.user_email, id: accessRequest.user_id }
                            }
                        })
                    }
                    next({ ...action, data: editFarm })
                })
            } else {
                next({ ...action, data: editFarm })
            }
        break;
        case SET_FARM_EDITS:
            const { edit } = getState()
            const { formData } = action
            if ( !edit ) {
                next({ ...action, edits: [] })
                return
            }
            apiCall('get', `/api/farm/admin/fetchFarmEdits/${edit.properties.id}`)
            .then( requestedEdits => {
                if ( requestedEdits.length ) {
                    let editsObj = {} 
                    requestedEdits.forEach( requestedEdit => {
                        Object.keys(requestedEdit).forEach( editKey => {
                            if ( JSON.stringify(requestedEdit[editKey]) !== JSON.stringify( formData[editKey] )) {
                                const requestObj = {
                                    key: editKey,
                                    value: requestedEdit[editKey],
                                    modifiedBy: { email: requestedEdit['modifiedBy'], id: requestedEdit['user_id'] }
                                }
                                if ( editsObj[editKey] === undefined ) {
                                editsObj[editKey] = [requestObj]
                                } else {
                                editsObj[editKey].push(requestObj)
                                }
                            }
                        })
                    })
                    next({ ...action, edits: editsObj })
                } else {
                    next({ ...action, edits: {} })
                }
            })
        break;
        case UPDATE_FARMS_LIST:
            const updatedFarm = action.data
            const originalFarm = getState().edit
            const updatedFarms = farm
            if ( originalFarm ) {
                const updatedFarmsList = farm.features.map(farmObj => {
                    if ( farmObj.properties.id === originalFarm.properties.id ) {
                        const updatedFarmObj = updatedFarm 
                        return updatedFarmObj
                    }
                    return farmObj
                })
                updatedFarms.features = updatedFarmsList
            } else {
                updatedFarms.features.push(updatedFarm)
            }
            
            // the farms need to be updated before this happens :/ 
            // dispatch(setFarmEdits(null))
            next({ type: SET_FARM_EDITS, edits: [] })
            next({ ...action, type: SET_FARMS, data: updatedFarms })
        break;
        default:
            if (typeof action === 'function') {
                return action(dispatch, getState)
            } else {
                next(action)
            }
        break;
    }
  }
  
  export default farms
  
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { apiCall } from '../../services/api';
import { setAlert } from '../../store/actions/alert';
import EditedFarms from './EditedFarms';
import { handleAccessFarm, fetchEditFarm, setEdit, fetchNewFarm } from '../../store/actions/farms';

const EditFarmsRequests = ({ user, header }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userEdits, setUserEdits] = useState([])
    useEffect(() => {
        if ( user ) {
            try {
                const data = apiCall('get', `/api/farm/admin/edited/${user._id}`).then( requests => {
                    setUserEdits(requests) 
                });
            } catch (err) {
                dispatch(setAlert({ message: err?.message }));
                return {};
            }
        }
    }, [user])

    const handleSubmit = (farm, status) => {
        dispatch(handleAccessFarm(user, farm.access_id, status));
    };

    const viewFarm = (farm) => {
        if (farm.access_id) {
            dispatch(fetchEditFarm(farm.access_id)).then((edit) => {
                let payload = {
                    type: 'Feature',
                    label: `${edit.name} (${edit.address})`,
                    geometry: {
                        type: 'Point',
                        coordinates: [edit.lon, edit.lat]
                    },
                    properties: {
                        ...edit
                    }
                }
                dispatch(setEdit(payload));
                history.push('/admin/view-change-farm');
            });
        } else if(farm.created_row_id) {
            dispatch(fetchNewFarm(farm.created_row_id)).then((edit) => {
                let payload = {
                    type: 'Feature',
                    label: `${edit.name} (${edit.address})`,
                    geometry: {
                        type: 'Point',
                        coordinates: [edit.lon, edit.lat]
                    },
                    properties: {
                        ...edit
                    }
                }
                dispatch(setEdit(payload));
                history.push('/admin/view-change-farm');
            });
        }
    };
    return (
        <>
            <Typography align="center" variant="h5">{ user.email } requests</Typography>
            { 
                Object.keys(userEdits).length > 0 && <EditedFarms editedFarms={userEdits} user={true} />
            }
        </>
    );
};

export default EditFarmsRequests;
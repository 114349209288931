import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { apiCall } from '../../services/api';
import { setAlert } from '../../store/actions/alert';
import EditedFarms from './EditedFarms';

const FeedbackSubmissions = ({ user, header }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [feedbackSubmissions, setFeedbackSubmissions] = useState([])
    useEffect(() => {
      try {
          apiCall('get', `/api/feedback`).then( requests => {
              setFeedbackSubmissions(requests) 
          });
      } catch (err) {
          dispatch(setAlert({ message: err?.message }));
          return {};
      }
    }, [])
    
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      
      // Format date parts
      const month = date.getMonth() + 1; // Month is zero-based
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      
      // Format time as AM/PM
      const amOrPm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      
      // Format the date and time
      const formattedDate = `${month}/${day}/${year}`;
      const formattedTime = `${formattedHours}:${String(minutes).padStart(2, '0')}${amOrPm}`;
      
      return `${formattedDate} ${formattedTime}`;
    }

    return (
        <>
            <Typography align="center" variant="h5">Feedback</Typography>
            { 
                feedbackSubmissions.length > 0 && (
                  <>
                    {
                      feedbackSubmissions.map( (feedback, i) => {
                        return (
                          <Grid key={`feedback-${i}`} item sx={{ marginTop: 2 }}>
                            <Box
                              sx={{
                                padding: 2,
                                border: 1,
                                borderRadius: "5px",
                                borderColor: "grey.500",
                                marginBottom: 1,
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="section"
                                sx={{
                                  display: "flex",
                                  gap: "6px"
                                }}
                              >
                                <Typography align="center" variant="p" sx={{ fontWeight: 'bold' }}>
                                  Feedback: 
                                </Typography>
                                <Typography align="center" variant="p">
                                  {feedback.feedback}
                                </Typography>
                              </Box>
                              <Box
                                component="span"
                                sx={{
                                  display: "flex",
                                  gap: "6px"
                                }}
                              >
                                <Typography align="center" variant="p" sx={{ fontWeight: 'bold' }}>
                                  Page:
                                </Typography>
                                <Typography align="center" variant="p">
                                  {feedback.page}
                                </Typography>
                              </Box>
                              {
                                feedback.email && (
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      gap: "6px"
                                    }}
                                  >
                                    <Typography align="center" variant="p" sx={{ fontWeight: 'bold' }}>
                                      Email:
                                    </Typography>
                                    <Typography align="center" variant="p">
                                      {feedback.email}
                                    </Typography>
                                  </Box>
                                )
                              }
                              <Box
                                component="span"
                                sx={{
                                  display: "flex",
                                  gap: "6px"
                                }}
                              >
                                <Typography align="center" variant="p" sx={{ fontWeight: 'bold' }}>
                                  Date/Time:
                                </Typography>
                                <Typography align="center" variant="p">
                                  { formatDate(feedback.createdAt) }
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )
                      })
                    }
                  </>
                )
            }
        </>
    );
};

export default FeedbackSubmissions;
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

export const VerifyDialog = ({
  open,
  handleClose,
  handleVerify,
  geocodes
}) => {
  const [formatted, setFormatted] = useState(geocodes.length ? geocodes[0] : null);

  useEffect( () => {
    setFormatted(geocodes.length ? geocodes[0] : null)
  }, [geocodes])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`${
        geocodes.length > 1 ? 'Select' : 'Verify'
      } Location`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {geocodes.length > 1
            ? 'Google found multiple locations for the address you entered. Please select one.'
            : 'Is this the correct location?'}
        </DialogContentText>
        {
          geocodes.length && 
            <>
              {
              geocodes.length > 1
              ? <RadioGroup
                  value={formatted}
                  onChange={(e) => setFormatted(geocodes[e.target.value])}
                >
                  {geocodes.map( d => (
                    <FormControlLabel
                      label={d.formattedAddress}
                      value={d}
                      key={d.formattedAddress}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              : <p>{ geocodes[0].formattedAddress }</p>
              }
            </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={ () => handleVerify(formatted)}>Select</Button>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteDialog = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this location from the database?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="error" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

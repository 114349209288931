import { SET_MATCHED_USER } from '../actionTypes';

const matchedUser = (state = [], action) => {
  switch (action.type) {
    case SET_MATCHED_USER:
      return action.user;
    default:
      return state;
  }
};

export default matchedUser;

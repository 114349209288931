export const SET_ALERT = 'SET_ALERT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_FARMS = 'SET_FARMS';
export const SET_EDITED_FARMS = 'SET_EDITED_FARMS';
export const SET_MARKETS = 'SET_MARKETS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_GEOCODED = 'SET_GEOCODED';
export const SET_EDIT = 'SET_EDIT';
export const SET_BULK_EDIT = 'SET_BULK_EDIT';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_USERS = 'SET_USERS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_APPROVALS = 'SET_APPROVALS';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_LAYERS = 'SET_LAYERS';
export const CLEAR_LAYERS = 'CLEAR_LAYERS';
export const SET_DISPLAY = 'SET_DISPLAY';
export const SET_COLOR_BY = 'SET_COLOR';
export const SET_SIZE_BY = 'SET_SIZE';
export const SET_DENSITY_BY = 'SET_DENSITY';
export const SET_POI = 'SET_POI';
export const FILTER_FARMS = 'FILTER_FARMS';
export const COLLECT_FARMS = 'COLLECT_FARMS';
export const SET_REQUEST_FARM = 'SET_REQUEST_FARM';
export const SET_ACCESS_FARMS = 'SET_ACCESS_FARMS';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_MATCHED_USER = 'SET_MATCHED_USER';
export const SET_FARM_EDITS = 'SET_FARM_EDITS';
export const SET_FIELD_EDIT = 'SET_FIELD_EDIT';
export const UPDATE_FARMS_LIST = 'UPDATE_FARMS_LIST';
export const SET_FEEDBACK = 'SET_FEEDBACK';
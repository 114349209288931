import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVDownloader } from "react-papaparse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { handleAccessFarm } from "../../store/actions/farms";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/actions/users";

const EditedFarms = ({ editedFarms, pendingUsers = [], user }) => {
  const [activeList, setActiveList] = useState("");
  const [approvedList, setApprovedList] = useState([]);
  const [approving, setApproving] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event, selectedList) => {
    if (selectedList !== null) {
      setActiveList(selectedList);
    }
  };

  const handleAccess = (userObj, farm, status) => {
    setApproving(farm.farm_id);
    dispatch(handleAccessFarm(userObj, farm, status)).then((approval) =>
      setApprovedList([...approvedList, farm.farm_id])
    );
  };

  const history = useHistory();

  const toggleButtonsList = useMemo(() => {
    const initialList = Object.keys(editedFarms).map((editType) => ({
      value: editType,
      label: editedFarms[editType].label,
      badgeCount: editedFarms[editType].farms.length,
    }));
    if (pendingUsers.length > 0) {
      initialList.push({
        value: "user",
        label: "Pending Users",
        badgeCount: pendingUsers.length,
      });
    }
    setActiveList(initialList[0].value);
    return initialList;
  }, [editedFarms, pendingUsers.length]);

  const handleUpdateUserStatus = useCallback((pendingUser, isApproved) => {
    const payload = {
      _id: pendingUser._id,
      email: pendingUser.email,
      role: pendingUser.role,
      originalRole: pendingUser.role,
      status: isApproved,
      originalStatus: pendingUser.status,
      isRejected: !isApproved,
    };
    dispatch(updateUser(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item sx={{ marginTop: 4 }}>
      <ToggleButtonGroup
        value={activeList}
        onChange={handleChange}
        exclusive
        fullWidth
        aria-label="text formatting"
      >
        {toggleButtonsList.map((item, i) => {
          return (
            <ToggleButton
              key={item.value}
              color="primary"
              value={item.value}
              aria-label={item.label}
              style={{
                flex: 1,
                paddingLeft: item.label.length > 15 ? 32 : 16,
                paddingRight: item.label.length > 15 ? 32 : 16,
              }}
            >
              <Typography
                variant="button"
                marginLeft={item.badgeCount > 0 ? -2 : 0}
              >
                {item.label}
              </Typography>
              {item.badgeCount > 0 && (
                <Badge
                  sx={{ top: -2, right: -16 }}
                  badgeContent={item.badgeCount}
                  color="primary"
                />
              )}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {activeList && (
        <Grid item sx={{ marginTop: 4 }}>
          <>
            {activeList === "user"
              ? pendingUsers.map((userItem) => (
                  <React.Fragment key={userItem._id}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", xs: "column" },
                        flexWrap: { sm: "wrap", xs: "nowrap" },
                        padding: 2,
                        border: 1,
                        borderRadius: "5px",
                        borderColor: "grey.500",
                        marginBottom: 2,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          flex: 1,
                          textAlign: { md: "left", xs: "center" },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            lineHeight: 1.2,
                            mb: 1,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          {userItem.email}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          textTransform={"capitalize"}
                          sx={{ lineHeight: 1 }}
                        >
                          {userItem.role}
                        </Typography>
                      </Box>
                      <Grid
                        item
                        sx={{
                          marginLeft: { md: 1, xs: 0 },
                          marginTop: { md: 0, xs: 2 },
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => handleUpdateUserStatus(userItem, true)}
                          variant="contained"
                          sx={{ position: "relative", marginRight: ".5rem" }}
                          color="primary"
                        >
                          Approve
                        </Button>
                        <Button
                          size="small"
                          onClick={() =>
                            handleUpdateUserStatus(userItem, false)
                          }
                          variant="contained"
                          color="error"
                          sx={{ position: "relative", marginRight: ".5rem" }}
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Box>
                  </React.Fragment>
                ))
              : editedFarms[activeList].farms.map((farm) => {
                  return (
                    <div key={`edit-farm-${farm._id}`}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", xs: "column" },
                          flexWrap: { sm: "wrap", xs: "nowrap" },
                          padding: 2,
                          border: 1,
                          borderRadius: "5px",
                          borderColor: "grey.500",
                          marginBottom: 2,
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            flex: 1,
                            textAlign: { md: "left", xs: "center" },
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              lineHeight: 1.2,
                              mb: 1,
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                          >
                            {farm.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ lineHeight: 1 }}
                          >
                            {farm.address}
                          </Typography>{" "}
                          {farm.needsApproval && (
                            <Typography variant="caption">
                              Created by: {farm.modifiedBy}
                            </Typography>
                          )}
                        </Box>
                        <Grid
                          item
                          sx={{
                            marginLeft: { md: 1, xs: 0 },
                            marginTop: { md: 0, xs: 2 },
                          }}
                        >
                          {farm.hasEdits && (
                            <Button
                              size="small"
                              onClick={() => {
                                history.push(`/farm/edit/${farm._id}`);
                              }}
                              variant="contained"
                            >
                              View Edits
                            </Button>
                          )}
                          {(farm.needsApproval || farm.origin_farm_id) && (
                            <Button
                              size="small"
                              onClick={() => {
                                history.push(`/farm/edit/${farm._id}`);
                              }}
                              variant="contained"
                            >
                              View Farm
                            </Button>
                          )}
                          {farm.status && !user && (
                            <>
                              <Button
                                size="small"
                                onClick={() => {
                                  handleAccess(
                                    {
                                      email: farm.user_email,
                                      id: farm.user_id,
                                    },
                                    { ...farm, id: farm.farm_id },
                                    "approve"
                                  );
                                }}
                                variant="contained"
                                sx={{
                                  position: "relative",
                                  marginRight: ".5rem",
                                }}
                                color="primary"
                                disabled={
                                  (activeList === "accessRequests" &&
                                    approving === farm.farm_id) ||
                                  (activeList === "accessRequests" &&
                                    approvedList.includes(farm.farm_id))
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  handleAccess(
                                    {
                                      email: farm.user_email,
                                      id: farm.user_id,
                                    },
                                    { ...farm, id: farm.farm_id },
                                    "reject"
                                  );
                                }}
                                variant="contained"
                                color="error"
                                sx={{
                                  position: "relative",
                                  marginRight: ".5rem",
                                }}
                                disabled={
                                  (activeList === "accessRequests" &&
                                    approving === farm.farm_id) ||
                                  (activeList === "accessRequests" &&
                                    approvedList.includes(farm.farm_id))
                                }
                              >
                                Reject
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  history.push(`/farm/edit/${farm.farm_id}`);
                                }}
                                variant="outlined"
                                sx={{ position: "relative" }}
                              >
                                View Access Request(s)
                                <Badge
                                  sx={{ top: -14, right: -10 }}
                                  badgeContent={farm.count}
                                  color="primary"
                                />
                              </Button>
                            </>
                          )}
                          {user && farm.status && (
                            <>
                              <Button
                                size="small"
                                onClick={() => {
                                  handleAccess(
                                    {
                                      email: farm.user_email,
                                      id: farm.user_id,
                                    },
                                    { ...farm, id: farm.farm_id },
                                    "approve"
                                  );
                                }}
                                variant="contained"
                                color="primary"
                                sx={{
                                  position: "relative",
                                  marginRight: ".75rem",
                                }}
                                disabled={
                                  (activeList === "accessRequests" &&
                                    approving === farm.farm_id) ||
                                  (activeList === "accessRequests" &&
                                    approvedList.includes(farm.farm_id))
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  handleAccess(
                                    {
                                      email: farm.user_email,
                                      id: farm.user_id,
                                    },
                                    { ...farm, id: farm.farm_id },
                                    "reject"
                                  );
                                }}
                                variant="contained"
                                color="error"
                                sx={{
                                  position: "relative",
                                  marginRight: ".5rem",
                                }}
                                disabled={
                                  (activeList === "accessRequests" &&
                                    approving === farm.farm_id) ||
                                  (activeList === "accessRequests" &&
                                    approvedList.includes(farm.farm_id))
                                }
                              >
                                Reject
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  history.push(`/farm/edit/${farm.farm_id}`);
                                }}
                                variant="text"
                                sx={{ position: "relative" }}
                              >
                                View Farm
                              </Button>
                            </>
                          )}
                        </Grid>
                      </Box>
                    </div>
                  );
                })}
          </>
          {activeList === "newfarmRequests" && (
            <Button variant="contained" startIcon={<DownloadIcon />}>
              <CSVDownloader
                data={editedFarms.newFarmRequests}
                filename="botanyc_approvals"
              >
                Approvals
              </CSVDownloader>
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default EditedFarms;

import { apiCall } from '../../services/api';
import { SET_FEEDBACK } from '../actionTypes';

const setFeedback = (payload) => {
  return {
    type: SET_FEEDBACK,
    payload,
  }};

export function submitFeedback(feedback) {
  return async (dispatch) => {
    try {
      await apiCall('post', '/api/feedback/submit', {feedback});
      dispatch(
        setFeedback({
          ...feedback,
          error: false
        })
      );
    } catch (err) {
      console.error('feedback error', err)
      dispatch(
        setFeedback({
          ...feedback,
          feedback: null,
          error: true
        }
      ));
    }
  };
}

export function resetFeedback() {
  return async (dispatch) => {
    dispatch(
      setFeedback({
        feedback: null,
        page: null,
        email: null,
        error: false
      })
    );
  };
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const FarmEdits = ({ name, type, onClick }) => {

    const [showEdits, setShowEdits] = useState(false);
    const [badgeColor, setBadgeColor] = useState("error");
    const edits = useSelector(state => state.farmEdits)
    
    const fieldEdits = Object.keys(edits)
    .filter( editKey => editKey === name )
    .map((editKey) => {
      return edits[editKey]
    });
    
    const handleSelectEdit = value => {
        onClick(value)
        setShowEdits(false)
        setBadgeColor("primary")
    }

    if ( !fieldEdits.length ) return <></>
    return (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button
                    variant="outlined"
                    onClick={() => setShowEdits(true)}
                    sx={{ marginBottom: '1rem' }}
                    size="small"
                    endIcon={<Badge 
                        style={{ cursor: 'pointer', right: '-6px', top: '-12px' }} 
                        badgeContent={fieldEdits[0].length} 
                        color={badgeColor}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}/>}>
                    Proposed Edits
                </Button>
            </div>
            {
                showEdits && 
                <Dialog onClose={() => setShowEdits(false)} open={showEdits}>
                    <List sx={{ pt: 0, minWidth: 320 }}>
                        {fieldEdits[0].map( (edit, editInd) => {
                            let editVal = edit.value 
                            switch( type ) {
                                case 'array':
                                    editVal = edit.value.join(', ')
                                break;
                                case 'date':
                                    const mongoDate = new Date(editVal)
                                    editVal = `${mongoDate.getMonth() + 1}/${mongoDate.getDate()}/${mongoDate.getFullYear()}`
                                break;
                                default:
                                break;
                            }
                            
                            return (
                                <ListItem button onClick={() => handleSelectEdit(edit)} key={`${edit.key}-${editInd}`}>
                                    <ListItemText primary={editVal} secondary={edit.modifiedBy.email}/>
                                </ListItem>
                            )
                        })}
                    </List>
                </Dialog>
            }
        </>
    )
}

export default FarmEdits;
import { SET_FEEDBACK } from '../actionTypes';

const initialState = {
  feedback: null,
  page: null,
  email: null,
  error: false
};
const feedback = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEEDBACK:
      return {...action.payload};
    default:
      return state;
  }
};

export default feedback;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authUser, forgotPassword, checkToken, setCurrentUser } from '../store/actions/auth';
import { fetchRoles } from '../store/actions/users';
import { validate } from 'react-email-validator';
import Header from './Header';
import RoleSelector from './register/RoleSelector';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';


const AuthForm = ({ authType, buttonText, heading }) => {
  const formErrors = {
    username: false,
    email: false,
    password: false,
    passwordConfirm: false,
    farms: false,
  }
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [email, setEmail] = useState('');
  const [relationship, setRelationship] = useState('');
  const [errors, setErrors] = useState(formErrors)
  const [role, setRole] = useState('grower');
  const currentUser = useSelector((state) => state.currentUser);
  const roles = useSelector((state) => state.userRoles);
  const farms = useSelector((state) => state.farm) || [];
  const [selectedFarms, setSelectedFarms] = useState([]);
  const [selectedFarmsArr, setSelectedFarmsArr] = useState([]);
  const [autoCompleteText, setAutoCompleteText] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const resetToken = useParams().token;

  useEffect(() => {
    localStorage.removeItem('signup_user');
    localStorage.removeItem('requestFarms');
    dispatch(setCurrentUser({}));
    dispatch(fetchRoles());
  }, [dispatch])

  const handleSubmit = () => {
    const currentFormErrors = formErrors
    if ( authType === 'signup' && (username.length <= 3 || password.length <= 3) ) {
      currentFormErrors.username = 'Username should be at least 3 characters'
    }
    if ( authType === 'signin' && !username.length ) {
      currentFormErrors.username = 'Field cannot be blank'
    }
    if ( (authType === 'signup' || authType === 'forgot') && !validate(email) ) {
      currentFormErrors.email = 'Please provide a valid email'
    }
    if ( authType === 'signup' && password.length <= 3 ) {
      currentFormErrors.password = 'Please create a password with at least 3 characters'
    }
    if ( authType === 'signin' && !password.length ) {
      currentFormErrors.password = 'Field cannot be blank'
    }
    if ( authType === 'signup' && password !== passwordConfirm )  {
      currentFormErrors.passwordConfirm = 'Passwords do not match'
    }
    if ( authType === 'signup' && !selectedFarms.length ) {
      currentFormErrors.farms = 'Please select at least 1 farm/garden'
    }
    if ( authType === 'signup' && autoCompleteText !== '' ) {
      currentFormErrors.farms = 'highlight field and click return to apply custom entry'
    }
    let relationshipErrors = []
    if (authType === 'signup') {
      relationshipErrors = selectedFarmsArr.map( selectedFarm => {
        if ( selectedFarm.relationship === '' ) {
          selectedFarm.error = true
        }
        return selectedFarm
      })
      setSelectedFarmsArr(relationshipErrors)
    }

    setErrors(currentFormErrors)
    if ( 
      Object.keys(currentFormErrors).filter( errorKey => currentFormErrors[errorKey] !== false ).length || 
      ( authType === 'signup' && relationshipErrors.filter( farmRelationship => farmRelationship.error ).length ) 
    ) return
    switch(authType) {
      case 'forgot':
        dispatch(forgotPassword(email));
      break;
      default:
        const submitSelectedFarms = selectedFarmsArr.map( selectedFarm => {
          return { farm: selectedFarm.farm, relationship: selectedFarm.relationship }
        })

        dispatch(authUser(authType, username, password, email, resetToken, 'grower', submitSelectedFarms));
    }
  };

  useEffect(() => {
    if (authType === 'reset') {
      dispatch(checkToken(resetToken));
    }
  }, [authType, dispatch, resetToken]);

  useEffect(() => {
    setSelectedFarmsArr( selectedFarms.map( selectedFarm => {
      selectedFarm = { farm: typeof selectedFarm === 'object' ? selectedFarm.properties.name : selectedFarm, relationship: '', error: false }
      return selectedFarm
    }) )
  }, [selectedFarms]);

  useEffect(() => {
    if (roles.length) {
      setRole(roles[1].value);
    }
  }, [roles])

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      const path = authType === 'signup' ? '/farm' : '/';
      history.push(path);
    } else if (currentUser.user.id) {
      let index = roles.findIndex(it => it.value === role);
      if (index > -1) {
        if (!currentUser.isAuthenticated && currentUser.user.role === 'grower') {
          history.push('/request-access');
        } else if (!currentUser.isAuthenticated && !roles[index].editFarm) {
          history.push('/completed-register');
        }
      }
    }
  }, [authType, currentUser, history, role, roles]);

  const signUpIn = ['signin', 'signup'].includes(authType);

  return (
    <div>
      <Header text={heading} align="center" />
      <Stack direction="column" style={{ maxWidth: 500, width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
        {authType === 'signup' && roles && (
          <div style={{ display: 'none' }}>
            <RoleSelector
              value='grower'
              setter={setRole}
              roles={roles}
            />
          </div>
        )}        
        <Stack width="100%" spacing={2}>
          {signUpIn && (
            <TextField
              label="Username"
              value={username}
              error={errors.username !== false}
              helperText={errors.username}
              onChange={(e) => { setUsername(e.target.value); setErrors({...errors, username: false }) }}
            />
          )}
          {(signUpIn || authType === 'reset') && (
            <TextField
              label="Password"
              type="password"
              value={password}
              error={errors.password !== false}
              helperText={errors.password}
              onChange={(e) => { setPassword(e.target.value); setErrors({...errors, password: false }) }}
            />
          )}
          {authType === 'signup' && (
            <TextField
              type="password"
              label="Confirm Password"
              value={passwordConfirm}
              error={errors.passwordConfirm !== false}
              helperText={errors.passwordConfirm}
              onChange={(e) => { setPasswordConfirm(e.target.value); setErrors({...errors, passwordConfirm: false }) }}
            />
          )}
          { (authType === 'forgot' || authType === 'signup') && (
            <TextField
              label="Email"
              value={email}
              onChange={(e) => { setEmail(e.target.value); setErrors({...errors, email: false }) }}
              error={errors.email !== false}
              helperText={errors.email}
            />
          )}
          { (authType === 'signup' && farms?.features?.length) && (
            <>
              <Autocomplete
                freeSolo={true}
                value={selectedFarms}
                multiple
                options={farms.features}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return <Chip variant="outlined" label={typeof option === 'object' ? option.label : option} {...getTagProps({ index })} />
                })
                }
                onChange={ (e, v) => { setErrors({...errors, farms: false }); setSelectedFarms(v); setAutoCompleteText('') } }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Associated farm/garden(s)"
                    error={errors.farms !== false}
                    onChange={ e => setAutoCompleteText(e.target.value) }
                    helperText={errors.farms ? errors.farms : null}
                  />
                )}
              />
              <sub>
                Begin typing to search and select the farm/garden(s) you're associated with.<br/><br/>
                <span>*If you cannot find your farm/garden(s), write in the name and address and click <b>return</b></span>
              </sub>
              {
                selectedFarmsArr.length > 0 &&
                <>
                  <sub><b>Provide a brief description of your involvement with your selected location(s):</b></sub>
                  {
                    selectedFarmsArr.map( (selectedFarm, farmInd) => <TextField
                      type="text"
                      label={`Relationship to ${selectedFarm.farm}`}
                      value={selectedFarm.relationship}
                      error={selectedFarm.error}
                      helperText={selectedFarm.error ? 'This field cannot be blank' : null}
                      onChange={(e) => { 
                        let farmsArr = [].concat(selectedFarmsArr)
                        farmsArr[farmInd] = { ...selectedFarmsArr[farmInd], relationship: e.target.value, error: false}
                        setSelectedFarmsArr(farmsArr); 
                      }}
                    />)
                  }
                </>
              }
            </>
          )}
          <Box width="100%" />
          <Stack alignItems="center" direction={'column'} spacing={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
            >
              {buttonText}
            </Button>
            {authType === 'signin' && (
              <Button
                size="small"
                onClick={() => history.push('/forgot')}
                disabled={validate(email) || signUpIn ? false : true}
              >
                Forgot Password
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default AuthForm;

import { apiCall } from '../../services/api';
import {
  SET_FARMS,
  SET_SELECTED,
  SET_SEARCH,
  SET_EDIT,
  SET_BULK_EDIT,
  SET_APPROVALS,
  SET_EDITED_FARMS,
  SET_ACCESS_FARMS,
  SET_FARM_EDITS,
  UPDATE_FARMS_LIST
} from '../actionTypes';
import { setAlert } from './alert';

export function setEditedFarms(data) {
  return {
    type: SET_EDITED_FARMS,
    data,
  };
}

export function fetchEditedFarms() {
  return async (dispatch) => {
    try {
      const data = await apiCall('get', '/api/farm/admin/edited');
      dispatch(setEditedFarms(data));
      return data;
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
      return {};
    }
  };
}

export function setFarms(data) {
  return {
    type: SET_FARMS,
    data,
  };
}

export function fetchFarms() {
  return async (dispatch) => {
    try {
      const data = await apiCall('get', '/api/farms');
      dispatch(setFarms(data));
      return data;
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
      return {};
    }
  };
}

export function setSelected(data) {
  return {
    type: SET_SELECTED,
    data,
  };
}

export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export function submitFarm(data) {
  return async (dispatch) => {
    try {
      const result = await apiCall('post', '/api/farm/approval/submit', data);
      let message = `Successfully added ${result.name}!`
      if ( data.original ) {
        message = `Successfully updated ${result.name}!`
      }
      dispatch(setAlert({ severity: 'success', message }));
      const updatedFarm = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [result.lon, result.lat],
        },
        properties: { ...result, id: result.id }
      }
      updatedFarm.formType = data.original ? 'edit' : 'create'
      dispatch(updateFarmsList(updatedFarm)); // make them available for FarmForm
      if ( updatedFarm.formType === 'create' ) {
        dispatch(setEdit(null))
      }
      return result
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function updateFarmsList(data) {
  return {
    type: UPDATE_FARMS_LIST,
    data
  }
}

export function setEdit(formType, farmId) {
  return {
    type: SET_EDIT,
    formType,
    farmId
  };
}

export function editOne(data) {
  return async (dispatch) => {
    try {
      const result = await apiCall('post', '/api/farm/auth/edit', data);
      const verb = result.lastErrorObject.updatedExisting
        ? 'updated'
        : 'inserted';
      const message = `Successfully ${verb} ${result.value.name}!`;
      dispatch(setAlert({ severity: 'success', message }));
      return dispatch(fetchFarms());
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function setBulkEdit(result) {
  return {
    type: SET_BULK_EDIT,
    result,
  };
}

export function editBulk(data) {
  return async (dispatch) => {
    try {
      const result = await apiCall('post', '/api/farm/admin/bulk', data);
      dispatch(setBulkEdit(result));
      dispatch(
        setAlert({ severity: 'success', message: 'Bulk write finished!' })
      );
      return dispatch(fetchFarms());
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function deleteOne(data) {
  return async (dispatch) => {
    try {
      await apiCall('post', '/api/farm/auth/delete', data);
      const message = `Successfully deleted ${data.data.name}!`;
      dispatch(setAlert({ severity: 'success', message }));
      return dispatch(fetchFarms());
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function setApprovals(data) {
  return {
    type: SET_APPROVALS,
    data,
  };
}

export function fetchApprovals() {
  return async (dispatch) => {
    try {
      const data = await apiCall('get', '/api/farm/admin/approvals');
      dispatch(setApprovals(data));
    } catch (err) {
      dispatch(setApprovals({}));
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function setAccessFarms(data) {
  return {
    type: SET_ACCESS_FARMS,
    farms: data,
  };
}

export function fetchAccesses(user_id) {
  return async(dispatch) => {
   try {
    const accesses = await apiCall('get', `/api/farm/admin/fetchAccesses/${user_id}`);
    const farms = accesses ? accesses.farms : []
    if ( farms.length ) {
      dispatch(setAccessFarms(farms));
    }
   } catch (err) {
    dispatch(setAccessFarms([]));
    dispatch(setAlert({ message: err?.message }));
   }
  }
}

export function handleAccessFarm(user, farm, status) {
  return async(dispatch) => {
    try {
      const payload = { user, farm, status }
      await apiCall('post', '/api/farm/admin/handleAccessFarm', payload);
      const userStatus = status === 'approve' ? 'Approved' : 'Rejected'
      const message = `Access ${userStatus} for ${user.email}!`;
      dispatch(setAlert({ severity: 'success', message }));
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  }
}

export function fetchEditFarm(access_id) {
  return async (dispatch) => {
    try {
      const results = await apiCall('get', `/api/farm/admin/fetchEditFarm/${access_id}`);
      return results;
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function fetchNewFarm(new_row_id) {
  return async (dispatch) => {
    try {
      const results = await apiCall('get', `/api/farm/admin/fetchNewFarm/${new_row_id}`);
      return results;
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function handleChangeFarm(payload) {
  return async (dispatch) => {
    try {
      return await apiCall('post', '/api/farm/admin/handleChangeFarm', payload);
    } catch (err) {
      dispatch(setAlert({message: err?.message}));
    }
  }
}

export function setFarmEdits(formData) {
  return {
    type: SET_FARM_EDITS,
    formData
  };
}

export function fetchFarmEdits(farmId) {
  return async (dispatch) => {
    try {
      const requestedEdits = await apiCall('get', `/api/farm/admin/fetchFarmEdits/${farmId}`);
      return requestedEdits
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function fetchFarmAccessRequests(farmId) {
  return async (dispatch) => {
    try {
      const accessRequests = await apiCall('get', `/api/farm/admin/fetchFarmAccessRequests/${farmId}`);
      return accessRequests
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}
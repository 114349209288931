import { apiCall, setTokenHeader } from '../../services/api';
import { SET_CURRENT_USER, SET_REQUEST_FARM } from '../actionTypes';
import { setAlert } from './alert';
import { setUsers } from './users';

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout() {
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(null);
    dispatch(setUsers([]));
    dispatch(setCurrentUser({}));
  };
}

export function authUser(type, username, password, email, resetToken, role, associatedFarms) {
  return async (dispatch) => {
    try {
      const userData = {
        username,
        password,
        email,
        resetToken: resetToken || null,
        role,
        associatedFarms
      };
      const { token, ...user } = await apiCall(
        'post',
        `/api/auth/${type}`,
        userData
      );
      if ( type !== 'signup' ) {
        if (user.status === 'pending') {
          setAuthorizationToken(token);
          dispatch(setAlert({message: 'Your account is pending approval.', severity: 'success'}));
          dispatch(setCurrentUser(user));
          return;
        } else {
          localStorage.setItem('jwtToken', token);
          setAuthorizationToken(token);
        }
      } else {
        localStorage.setItem('signup_user', JSON.stringify({user: user}));
      }
      dispatch(setCurrentUser(user));
      if (type !== 'signin') {
        const alert = {
          severity: 'success',
          message:
            type === 'reset'
              ? 'Password updated successfully'
              : `User:${username} created successfully`,
        };
        dispatch(setAlert(alert));
      }
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function forgotPassword(email) {
  return async (dispatch) => {
    try {
      await apiCall('post', '/api/auth/forgot', { email });
      dispatch(
        setAlert({
          severity: 'success',
          message: `Reset password link sent to ${email}`,
        })
      );
    } catch (err) {
      dispatch(setAlert({ message: err?.message.response }));
    }
  };
}

export function checkToken(token) {
  return async (dispatch) => {
    try {
      const result = await apiCall('get', `/api/auth/reset/${token}`);
      if (!result.email) {
        dispatch(setAlert({ message: 'Invalid/expired reset token' }));
      }
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}

export function addRequestFarm(farm) {
  let farms = JSON.parse(localStorage.getItem('requestFarms')) || []
  let shouldAddFarm = true
  farms = farms.map( localFarm => {
    if ( localFarm.properties.id === farm.properties.id ) {
      shouldAddFarm = false
      return farm
    }
    return localFarm
  })
  if ( shouldAddFarm ) {
    farms.push(farm)
  }
  localStorage.setItem('requestFarms', JSON.stringify(farms));
  return {
    type: SET_REQUEST_FARM,
    farms
  };
}

export function addRegisterRequestFarms(farms) {
  localStorage.setItem('requestFarms', JSON.stringify(farms));
  return {
    type: SET_REQUEST_FARM,
    farms
  };
}

export function removeRequestFarm(farmId) {
  let farms = JSON.parse(localStorage.getItem('requestFarms'))
  farms = farms.filter( farm => farm.properties.id !== farmId )
  localStorage.setItem('requestFarms', JSON.stringify(farms));
  return {
    type: SET_REQUEST_FARM,
    farms
  };
}

export function clearRequestFarms() {
  const farms = []
  localStorage.setItem('requestFarms', JSON.stringify(farms));
  return {
    type: SET_REQUEST_FARM,
    farms
  };
}

export function submitRequestFarms(payload) {
  return async (dispatch) => {
    try {
      const submitRequests = await apiCall('post', `/api/requestFarmAccess`,
        payload
      );
      dispatch(setAlert({message: 'Your requests have been submitted.', severity: 'success' }));
      return submitRequests
    } catch (err) {
      dispatch(setAlert({ message: err?.message }));
    }
  };
}
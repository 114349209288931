import { SET_FARM_EDITS } from '../actionTypes';

const farmEdits = (state = {}, action) => {
  switch (action.type) {
    case SET_FARM_EDITS:
      return action.edits;
    default:
      return state;
  }
};

export default farmEdits;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from '../Header';
import { logout } from '../../store/actions/auth';

const CompletedRegister = ({ heading }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const history = useHistory();

    useEffect(() => {
        if ( !currentUser.user.id || currentUser.user.status !== 'pending' ) {
            history.push("/signup");
        }
    }, [dispatch]);

    return (
        <div>
            <Grid container spacing={3} sx={{ marginTop: 2 }}>
                <Grid item xs />
                <Grid item xs={12}>
                    <Header text={'Thanks for registering!'} align="center" />
                    <Typography variant="body2" align="center" sx={{ maxWidth: 400, margin: 'auto' }}>
                        Your account is being reviewed. You will be notified via the provided email when your account is activated.
                    </Typography>
                    <Stack spacing={2}  justifyContent="start" alignItems="center"  sx={{ marginTop: 5, marginBottom: 10 }}>
                        <Button
                            variant="contained"
                            onClick={() => { history.push('/'); }}
                        >
                            View Map
                        </Button>

                    </Stack>
                </Grid>
                <Grid item xs />
            </Grid>
        </div>
    )
};

export default CompletedRegister;